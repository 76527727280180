<template>
  <v-container fluid>
    <crm-table-pageable-new
        :debounce-ms="1000"
        :headers="headers"
        :data="inputsData"
        :config="config"
        :loaded-options="options"
        :filters-dictionaries="filtersDictionaries"
        :available-filters.sync="availableFilters"
        :total-elements="totalElements"
        :total-pages="totalPages"
        @onChangeData="onChangeData"
    ></crm-table-pageable-new>
  </v-container>
</template>
<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';

export default {
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: 'Номер операции', type: 'defaultItem',
        },
        {
          value: 'created', sortable: true, text: 'Дата и время создания', type: 'defaultItem',
        },
        {
          value: 'type', sortable: false, text: 'Тип операции', type: 'defaultItem',
        },
        {
          value: 'name', sortable: false, text: 'Клиент', type: 'defaultItem',
        },
        {
          value: 'orderId', sortable: false, text: 'Номер заказа', type: 'defaultItem',
        },
        {
          value: 'transactionId', sortable: false, text: 'Номер РРН', type: 'defaultItem',
        },
        {
          value: 'completedDate', sortable: false, text: 'Дата операции', type: 'defaultItem',
        },
        {
          value: 'salesAmount', sortable: false, text: 'Сумма реализации', type: 'defaultItem',
        },
        {
          value: 'ofdAmount', sortable: false, text: 'Сумма ОФД', type: 'defaultItem',
        },
        {
          value: 'expenseType', sortable: false, text: 'Статья', type: 'defaultItem',
        },
      ],
      inputsData: [],
      config: {
        search: true,
        searchText: 'Поиск по клиенту',
        pageable: true,
      },
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: false,
        },
      ],
      totalElements: 0,
      totalPages: 0,
      url: 'PaymentOrder/IncomingOrder',
      options: {
        page: 1,
        size: 10,
      },
    };
  },
  created() {
    this.getContractors();
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getInputs();
    },
    getContractors() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$api.get('/api/v1/crm/billing/payment-order/input/contractors').then((res) => {
          this.$set(this.filtersDictionaries, 'contractors', res.map((i) => ({
            value: i,
            label: i,
          })));
          resolve();
        }).finally(() => this.$loading(false));
      });
    },
    getInputs() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$billing.get(formattedUrl, {
        params: {
          pageNumber: params.page,
          pageSize: params.size,
          sorting: params.sort,
          dateUntil: params.createdDateRange && params.createdDateRange.split(',')[1],
          dateFrom: params.createdDateRange && params.createdDateRange.split(',')[0],
          'search.value': params.search,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / (params.size || 1));
        this.inputsData = [...response.data].map((item) => ({
          ...item,
          transactionId: item.transactionId ? item.transactionId : 'Нет данных',
          name: item.name ? item.name : 'Нет данных',
          rrnNumber: item.rrnNumber ? item.rrnNumber : 'Нет данных',
          created: this.getFormattedDate(item.created),
          completedDate: this.getFormattedDate(item.completedDate),
          type: item.type ? item.type : 'Нет данных',
          expenseType: item.expenseType ? item.expenseType : 'Нет данных',
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
    getFormattedDate(date) {
      if (!this.$moment(date).isValid()) return 'Нет данных';
      return this.$moment(date).format('DD.MM.YYYY HH:mm');
    },
  },
};
</script>

<style scoped>

</style>
